/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "publicorderform",
            "endpoint": "https://1b5gbkb79f.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "wishwellapi",
            "endpoint": "https://jtmrrw8h2a.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "wishwellpayments",
            "endpoint": "https://q8h84qa5l1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://34ard54k2fae5btk7m5gyfn6ra.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:5370b462-2575-49e5-b91e-8d46025e1d6e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KFbidGG6K",
    "aws_user_pools_web_client_id": "1pthr4l7khoqs6op3873grrmn9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wishwell-etl-data94437-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
